import React from 'react'
import { BrowserRouter, Routes, Route } from 'react-router-dom'
import { useCookies } from 'react-cookie'
import { IS_DEV } from './config'
import { Homepage } from './homepage'
import { Plans } from './plans'
import { GetUsage } from './get_usage'
import { Enrollment } from './enrollment'
import { TOS } from './pages/terms-of-service'
import Footer from './components/footer'
import { PrivacyPolicy } from './pages/privacy-policy'
import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import { ThemeProvider } from 'styled-components'
import 'react-datetime/css/react-datetime.css'
import { library } from '@fortawesome/fontawesome-svg-core'
import qs from 'qs'
import {
    faFilePdf,
    faTimesCircle,
    faSearch,
    faCheckCircle,
    faCheckSquare,
    faCircle,
    faSquare,
    faBars,
    faArrowDown,
    faTimes,
    faSlidersH,
    faCaretUp,
    faCaretDown,
    faExchangeAlt,
} from '@fortawesome/free-solid-svg-icons'
import './i18n'
import { AboutUs } from './pages/about-us'
import { AboutUsCn } from './pages/about-us-cn'
import { ShoppingTips } from './pages/shopping-tips'
import { ShoppingTipsCn } from './pages/shopping-tips-cn'
import { SavingCalculation } from './pages/saving-calculation'
import { SavingCalculationCn } from './pages/saving-calculation-cn'
import { LetterOfAuthorization } from './pages/letter-of-authorization'
import { LetterOfAuthorizationCn } from './pages/letter-of-authorization-cn'

library.add(
    faFilePdf as any,
    faTimesCircle as any,
    faSearch as any,
    faCheckCircle as any,
    faCheckSquare as any,
    faCircle as any,
    faSquare as any,
    faBars as any,
    faArrowDown as any,
    faTimes as any,
    faSlidersH as any,
    faCaretUp as any,
    faCaretDown as any,
    faExchangeAlt as any
)

const theme = {
    colors: {
        primary: '#ED7549',
    },
}

function App() {
    const { promo_code } = qs.parse(window.location.search, { ignoreQueryPrefix: true });
    const [ cookies, setCookie ] = useCookies(['promo_code']);
    
    let baseUrl = '';

    if (typeof window !== undefined) {
        const pathname = window.location.pathname;
        if (pathname.indexOf('/dian') >= 0) {
            baseUrl = '/dian';
        }
    }

    if (promo_code) {
        setCookie('promo_code', promo_code, { path: '/', maxAge: 1209600 });
    }
    
    return (
        <ThemeProvider theme={theme}>
            <BrowserRouter basename={`${IS_DEV ? '/dev' : '/'}${baseUrl}`}>
                <Routes>
                    <Route path="/" element={<Homepage />} />
                    <Route path="/plans" element={<Plans />} />
                    <Route path="/get_usage" element={<GetUsage />} />
                    <Route path="/enrollment" element={<Enrollment />} />
                    {/* <Route path="/terms_of_service" element={<TOS />} /> */}
                    {/* <Route path="/privacy_policy" element={<PrivacyPolicy />} /> */}
                    {/* <Route path="/about_us" element={<AboutUs />} /> */}
                    {/* <Route path="/about_us_cn" element={<AboutUsCn />} /> */}
                    <Route path="/shopping_tips" element={<ShoppingTips />} />
                    {/* <Route path="/shopping_tips_cn" element={<ShoppingTipsCn />} /> */}
                    {/* <Route path="/saving_calculation_cn" element={<SavingCalculationCn />} /> */}
                    <Route path="/saving_calculation" element={<SavingCalculation />} />
                    <Route path="/letter_of_authorization" element={<LetterOfAuthorization />} />
                    {/* <Route path="/letter_of_authorization_cn" element={<LetterOfAuthorizationCn />} /> */}
                </Routes>
            </BrowserRouter>
            <Footer />
            <ToastContainer />
        </ThemeProvider>
    )
}

export default App
