import React from 'react';
import { LoadingRow } from '../components/loading-row';
import { CheckboxItem } from '../components/checkbox-item';
import type { UtilitiesFilterProps } from '../types';

export const UtilitiesFilter: React.FC<UtilitiesFilterProps> = ({
    initializeFilter,
    value,
    loading,
    handleClick,
    utilities
}) => {
    if (initializeFilter) return <LoadingRow size="sm" />

    return (
        <div className="plans-filter-options">
            {
                utilities.map((val:any, idx:any) => (
                    <CheckboxItem
                        label={val.utility_name}
                        value={val.utility_id}
                        key={idx}
                        checked={value !== '' ? value === val.utility_id : true}
                        handleClick={() => {
                            handleClick(val.utility_id)
                        }}
                        disabled={loading}
                    />
                ))
            }
        </div>
    )
}