import { tns } from "tiny-slider/src/tiny-slider";
import Sticky from "sticky-js";
var e = {
  init: function () {
    e.tinySlider();
    e.backTotop();
    e.stickyBar();
    e.stickyHeader();
  },
  isVariableDefined: function (el) {
    return typeof !!el && el != "undefined" && el != null;
  },
  getParents: function (el, selector, filter) {
    const result = [];
    const matchesSelector =
      el.matches ||
      el.webkitMatchesSelector ||
      el.mozMatchesSelector ||
      el.msMatchesSelector;

    // match start from parent
    el = el.parentElement;
    while (el && !matchesSelector.call(el, selector)) {
      if (!filter) {
        if (selector) {
          if (matchesSelector.call(el, selector)) {
            return result.push(el);
          }
        } else {
          result.push(el);
        }
      } else {
        if (matchesSelector.call(el, filter)) {
          result.push(el);
        }
      }
      el = el.parentElement;
      if (e.isVariableDefined(el)) {
        if (matchesSelector.call(el, selector)) {
          return el;
        }
      }
    }
    return result;
  },
  getNextSiblings: function (el, selector, filter) {
    let sibs = [];
    let nextElem = el.parentNode.firstChild;
    const matchesSelector =
      el.matches ||
      el.webkitMatchesSelector ||
      el.mozMatchesSelector ||
      el.msMatchesSelector;
    do {
      if (nextElem.nodeType === 3) continue; // ignore text nodes
      if (nextElem === el) continue; // ignore elem of target
      if (nextElem === el.nextElementSibling) {
        if (!filter || filter(el)) {
          if (selector) {
            if (matchesSelector.call(nextElem, selector)) {
              return nextElem;
            }
          } else {
            sibs.push(nextElem);
          }
          el = nextElem;
        }
      }
    } while ((nextElem = nextElem.nextSibling));
    return sibs;
  },
  on: function (selectors, type, listener) {
    document.addEventListener("DOMContentLoaded", () => {
      if (!(selectors instanceof HTMLElement) && selectors !== null) {
        selectors = document.querySelector(selectors);
      }
      selectors.addEventListener(type, listener);
    });
  },
  onAll: function (selectors, type, listener) {
    document.addEventListener("DOMContentLoaded", () => {
      document.querySelectorAll(selectors).forEach((element) => {
        if (type.indexOf(",") > -1) {
          let types = type.split(",");
          types.forEach((type) => {
            element.addEventListener(type, listener);
          });
        } else {
          element.addEventListener(type, listener);
        }
      });
    });
  },
  removeClass: function (selectors, className) {
    if (!(selectors instanceof HTMLElement) && selectors !== null) {
      selectors = document.querySelector(selectors);
    }
    if (e.isVariableDefined(selectors)) {
      selectors.removeClass(className);
    }
  },
  removeAllClass: function (selectors, className) {
    if (e.isVariableDefined(selectors) && selectors instanceof HTMLElement) {
      document.querySelectorAll(selectors).forEach((element) => {
        element.removeClass(className);
      });
    }
  },
  toggleClass: function (selectors, className) {
    if (!(selectors instanceof HTMLElement) && selectors !== null) {
      selectors = document.querySelector(selectors);
    }
    if (e.isVariableDefined(selectors)) {
      selectors.toggleClass(className);
    }
  },
  toggleAllClass: function (selectors, className) {
    if (e.isVariableDefined(selectors) && selectors instanceof HTMLElement) {
      document.querySelectorAll(selectors).forEach((element) => {
        element.toggleClass(className);
      });
    }
  },
  addClass: function (selectors, className) {
    if (!(selectors instanceof HTMLElement) && selectors !== null) {
      selectors = document.querySelector(selectors);
    }
    if (e.isVariableDefined(selectors)) {
      selectors.addClass(className);
    }
  },
  select: function (selectors) {
    return document.querySelector(selectors);
  },
  selectAll: function (selectors) {
    return document.querySelectorAll(selectors);
  },
  // START: 03 Sticky Header
  stickyHeader: function () {
    var stickyNav = e.select('.header-sticky');
    if (e.isVariableDefined(stickyNav)) {
      var stickyHeight = stickyNav.offsetHeight;
      stickyNav.insertAdjacentHTML('afterend', '<div id="sticky-space"></div>');
      var stickySpace = e.select('#sticky-space');
      if (e.isVariableDefined(stickySpace)) {
        document.addEventListener('scroll', function (event) {
          var scTop = window.pageYOffset || document.documentElement.scrollTop;
          if (scTop >= 400) {
            stickySpace.addClass('active');
            e.select('#sticky-space.active').style.height = stickyHeight + 'px';
            stickyNav.addClass('header-sticky-on');
          } else {
            stickySpace.removeClass('active');
            stickySpace.style.height = '0px';
            stickyNav.removeClass('header-sticky-on');
          }
        });
      }
    }
  },
  // END: Sticky Header
  // START: 04 Tiny Slider
  tinySlider: function () {
    var $carousel = e.select(".tiny-slider-inner");
    if (e.isVariableDefined($carousel)) {
      var tnsCarousel = e.selectAll(".tiny-slider-inner");
      tnsCarousel.forEach((slider) => {
        var slider1 = slider;
        var sliderMode = slider1.getAttribute("data-mode")
          ? slider1.getAttribute("data-mode")
          : "carousel";
        var sliderAxis = slider1.getAttribute("data-axis")
          ? slider1.getAttribute("data-axis")
          : "horizontal";
        var sliderSpace = slider1.getAttribute("data-gutter")
          ? slider1.getAttribute("data-gutter")
          : 30;
        var sliderEdge = slider1.getAttribute("data-edge")
          ? slider1.getAttribute("data-edge")
          : 0;

        var sliderItems = slider1.getAttribute("data-items")
          ? slider1.getAttribute("data-items")
          : 4; //option: number (items in all device)
        var sliderItemsXl = slider1.getAttribute("data-items-xl")
          ? slider1.getAttribute("data-items-xl")
          : Number(sliderItems); //option: number (items in 1200 to end )
        var sliderItemsLg = slider1.getAttribute("data-items-lg")
          ? slider1.getAttribute("data-items-lg")
          : Number(sliderItemsXl); //option: number (items in 992 to 1199 )
        var sliderItemsMd = slider1.getAttribute("data-items-md")
          ? slider1.getAttribute("data-items-md")
          : Number(sliderItemsLg); //option: number (items in 768 to 991 )
        var sliderItemsSm = slider1.getAttribute("data-items-sm")
          ? slider1.getAttribute("data-items-sm")
          : Number(sliderItemsMd); //option: number (items in 576 to 767 )
        var sliderItemsXs = slider1.getAttribute("data-items-xs")
          ? slider1.getAttribute("data-items-xs")
          : Number(sliderItemsSm); //option: number (items in start to 575 )

        var sliderSpeed = slider1.getAttribute("data-speed")
          ? slider1.getAttribute("data-speed")
          : 500;
        var sliderautoWidth = slider1.getAttribute("data-autowidth") === "true"; //option: true or false
        var sliderArrow = slider1.getAttribute("data-arrow") !== "false"; //option: true or false
        var sliderDots = slider1.getAttribute("data-dots") !== "false"; //option: true or false

        var sliderAutoPlay = slider1.getAttribute("data-autoplay") !== "false"; //option: true or false
        var sliderAutoPlayTime = slider1.getAttribute("data-autoplaytime")
          ? slider1.getAttribute("data-autoplaytime")
          : 4000;
        var sliderHoverPause =
          slider1.getAttribute("data-hoverpause") === "true"; //option: true or false
        if (e.isVariableDefined(e.select(".custom-thumb"))) {
          var sliderNavContainer = e.select(".custom-thumb");
        }
        var sliderLoop = slider1.getAttribute("data-loop") !== "false"; //option: true or false
        var sliderRewind = slider1.getAttribute("data-rewind") === "true"; //option: true or false
        var sliderAutoHeight =
          slider1.getAttribute("data-autoheight") === "true"; //option: true or false
        var sliderAutoWidth = slider1.getAttribute("data-autowidth") === "true"; //option: true or false
        var sliderfixedWidth =
          slider1.getAttribute("data-fixedwidth") === "true"; //option: true or false
        var sliderTouch = slider1.getAttribute("data-touch") !== "false"; //option: true or false
        var sliderDrag = slider1.getAttribute("data-drag") !== "false"; //option: true or false
        // Check if document DIR is RTL
        var ifRtl = document
          .getElementsByTagName("html")[0]
          .getAttribute("dir");
        var sliderDirection;
        if (ifRtl === "rtl") {
          sliderDirection = "rtl";
        }

        var tnsSlider = tns({
          container: slider,
          mode: sliderMode,
          axis: sliderAxis,
          gutter: sliderSpace,
          edgePadding: sliderEdge,
          speed: sliderSpeed,
          autoWidth: sliderautoWidth,
          controls: sliderArrow,
          nav: sliderDots,
          autoplay: sliderAutoPlay,
          autoplayTimeout: sliderAutoPlayTime,
          autoplayHoverPause: sliderHoverPause,
          autoplayButton: false,
          autoplayButtonOutput: false,
          controlsPosition: "top",
          navContainer: sliderNavContainer,
          navPosition: "top",
          autoplayPosition: "top",
          controlsText: [
            '<i class="bi bi-arrow-left"></i>',
            '<i class="bi bi-arrow-right"></i>',
          ],
          loop: sliderLoop,
          rewind: sliderRewind,
          autoHeight: sliderAutoHeight,
          autoWidth: sliderAutoWidth,
          fixedWidth: sliderfixedWidth,
          touch: sliderTouch,
          mouseDrag: sliderDrag,
          arrowKeys: true,
          items: sliderItems,
          textDirection: sliderDirection,
          responsive: {
            0: {
              items: Number(sliderItemsXs),
            },
            576: {
              items: Number(sliderItemsSm),
            },
            768: {
              items: Number(sliderItemsMd),
            },
            992: {
              items: Number(sliderItemsLg),
            },
            1200: {
              items: Number(sliderItemsXl),
            },
          },
        });
      });
    }
  },
  // END: Tiny Slider
  // START: 05 Sticky Bar
  stickyBar: function () {
    var sb = e.select("[data-sticky]");

    if (e.isVariableDefined(sb)) {
      var sticky = new Sticky("[data-sticky]");
    }
  },
  // END: Sticky Bar

  // START: 08 Back to Top
  backTotop: function () {
    var scrollpos = window.scrollY;
    var backBtn = e.select(".back-top");

    if (e.isVariableDefined(backBtn)) {
      var add_class_on_scroll = () => backBtn.addClass("back-top-show");
      var remove_class_on_scroll = () => backBtn.removeClass("back-top-show");

      window.addEventListener("scroll", function () {
        scrollpos = window.scrollY;
        if (scrollpos >= 800) {
          add_class_on_scroll();
        } else {
          remove_class_on_scroll();
        }
      });

      backBtn.addEventListener("click", () =>
        window.scrollTo({
          top: 0,
          behavior: "smooth",
        })
      );
    }
  },
  // END: Back to Top
};

export const setupActions = e.init;
