import React from 'react';
import { LoadingRow } from '../components/loading-row';
import { Dropdown } from 'react-bootstrap';
import type { PrepaidFilterProps } from '../types';

const prepaidOptions: Array<any> = [
    { label: 'Show All', value: 'all' },
    { label: 'Show Only Prepaid', value: 'true' },
    { label: 'Do Not Show Prepaid', value: 'false' }
];

export const PrepaidFilter: React.FC<PrepaidFilterProps> = ({
    initializeFilter,
    value,
    loading,
    handleClick
}) => {
    if (initializeFilter) return <LoadingRow size="sm" />

    return (
        <div className="plans-filter-options">
            <Dropdown className="plans-filter-dd">
                <Dropdown.Toggle variant="light" disabled={loading}>
                    {((val) => {
                        const res = prepaidOptions.filter((item) => item.value === val);
                        return res[0].label;
                    })(value)}
                </Dropdown.Toggle>
                <Dropdown.Menu>
                    {
                        prepaidOptions.map((val, idx) => (
                            <Dropdown.Item
                                eventKey={val}
                                key={idx}
                                onClick={() => {
                                    handleClick(val.value);
                                }}
                                active={value === val.value}
                            >{val.label}</Dropdown.Item>
                        ))
                    }
                </Dropdown.Menu>
            </Dropdown>
        </div>
    )
}