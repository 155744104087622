import { Container } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import EIQDigital from '../../public/images/eig_digital-logo.png';
import { StyledFooter, Copyright, FooterNavRow, FooterNavCol, ServiceLogo, ServiceLogoItem } from './styles/Footer.styled';
import { BLOG_URL, IS_DEV } from '../config';
import moment from 'moment';

interface Item {
  title: string;
  links: NavLink[];
}

interface NavLink {
  name: string;
  url: string;
  cnOnly?: boolean;
}

const Footer = () => {
  const { t, i18n } = useTranslation();
  const path = IS_DEV ? '/dev' : '';
  const Date = moment().format('YYYY');

  const nav: Item[] = [
    {
      title: t('Company'),
      links: [
        {
          name: t('About'),
          url: i18n.language === 'en' ? `${path}/about_us/` : `${path}/about_us_cn/`,
        },
        {
          name: t('Shopping Tips'),
          url: i18n.language === 'en' ? `${path}/shopping_tips/` : `${path}/shopping_tips_cn/`,
        },
        { name: t('Blog'), url: `${BLOG_URL}`, cnOnly: true },
        { name: t('Terms of Service'), url: `${path}/terms_of_service/` },
        { name: t('Privacy Policy'), url: `${path}/privacy_policy/` },
      ],
    },
    {
      title: t('Contact'),
      links: [
        {
          name: t('Customer Support'),
          url: i18n.language === 'en' ? `https://everlastenergy.com/contact-us/` : `${BLOG_URL}联系我们/`,
        },
        {
          name: t('General Inquires'),
          url: i18n.language === 'en' ? `https://everlastenergy.com/contact-us/` : `${BLOG_URL}联系我们/`,
        },
      ],
    },
    {
      title: t('Social Media'),
      links: [
        { name: 'Facebook', url: 'https://www.facebook.com/everlastenergydian' },
        // { name: 'Instagram', url: 'https://www.instagram.com/everlastenergy/' },
        // { name: 'Twitter', url: 'https://twitter.com/EverlastEnergy' },
        {
          name: 'Youtube',
          url: 'https://www.youtube.com/channel/UCwWwmJRst8I1JHijkUZRHug',
        },
        {
          name: '小红书',
          url: 'https://www.xiaohongshu.com/user/profile/61e252ee0000000010004b13?xhsshare=CopyLink&appuid=61e252ee0000000010004b13&apptime=1653586128',
          cnOnly: true,
        },
        {
          name: 'Wechat',
          url: '/images/wechat_qrcode.png',
          cnOnly: true,
        },
      ],
    },
  ];

  return (
    <footer className='bg-dark pt-5'>
      <div className='container'>
        {/* Row START */}
        <div className='row g-4'>
          {/* Widget 1 START */}
          <div className='col-lg-3'>
            {/* logo */}
            <a className='navbar-brand' href='#'>
              <img className='light-mode-item navbar-brand-item' src='images/logo.png' alt='logo' />
              <img className='dark-mode-item navbar-brand-item' src='images/logo.png' alt='logo' />
            </a>
            <p className='my-3 text-muted'>Your Local Energy Shopping Experts</p>
            <p className='mb-2'>
              <a href='tel:(346) 330-1875' className='text-muted text-primary-hover'>
                <i className='bi bi-telephone me-2' />
                (346) 330-1875
              </a>
            </p>
            <p className='mb-0'>
              <a href='mailto:th@everlastenergy.com' className='text-muted text-primary-hover'>
                <i className='bi bi-envelope me-2' />
                wecare@everlastenergy.com
              </a>
            </p>
          </div>
          {/* Widget 1 END */}
          {/* Widget 2 START */}
          <div className='col-lg-8 ms-auto'>
            <div className='row g-4'>
              {/* Widget 2 START */}
              <div className='col-lg-3 d-none'>
                <h5 className='text-white mb-2 mb-md-4'>Resources &amp; Quick Links</h5>
                <ul className='nav flex-column text-primary-hover'>
                  <li className='nav-item'>
                    <a className='nav-link text-muted' href='#'>
                      Search our Learning Center
                    </a>
                  </li>
                  <li className='nav-item'>
                    <a className='nav-link text-muted' href='#'>
                      57,000 + Real Customer Reviews for ComparePower
                    </a>
                  </li>
                  <li className='nav-item'>
                    <a className='nav-link text-muted' href='#'>
                      Stuck in an electricity contract?
                    </a>
                  </li>
                  <li className='nav-item'>
                    <a className='nav-link text-muted' href='#'>
                      Switch Electricity Companies
                    </a>
                  </li>
                  <li className='nav-item'>
                    <a className='nav-link text-muted' href='#'>
                      How Deregulation Works
                    </a>
                  </li>
                </ul>
              </div>
              {/* Widget 2 END */}
              {/* Widget 3 START */}
              <div className='col-lg-3 d-none'>
                <h5 className='text-white mb-2 mb-md-4'>Blog/News</h5>
                <ul className='nav flex-column text-primary-hover'>
                  <li className='nav-item'>
                    <a className='nav-link text-muted' href='#'>
                      Electricity Contracts: Know the Facts Before You Sign
                    </a>
                  </li>
                  <li className='nav-item'>
                    <a className='nav-link text-muted' href='#'>
                      How to Read Your Electricity Facts Label
                    </a>
                  </li>
                  <li className='nav-item'>
                    <a className='nav-link text-muted' href='#'>
                      Get Power ON Today
                    </a>
                  </li>
                  <li className='nav-item'>
                    <a className='nav-link text-muted' href='#'>
                      How Electricity Contract Renewals Work in Texas
                    </a>
                  </li>
                  <li className='nav-item'>
                    <a className='nav-link text-muted' href='#'>
                      How to Read Your Electricity Bill in Texas
                    </a>
                  </li>
                </ul>
              </div>
              {/* Widget 3 END */}
              {/* Widget 1 START */}
              <div className='col-lg-4 offset-md-8'>
                <h5 className='text-white mt-md-5 pt-md-4'>Company Info</h5>
                <ul className='nav flex-column text-primary-hover'>
                  <li className='nav-item'>
                    <a className='nav-link text-muted' href='https://career.everlastenergy.com/#whoweare'>
                      About Us
                    </a>
                  </li>
                  <li className='nav-item'>
                    <a className='nav-link text-muted' href='https://career.everlastenergy.com'>
                      Careers
                    </a>
                  </li>
                  <li className='nav-item'>
                    <a className='nav-link text-muted' href='mailto:th@everlastenergy.com'>
                      Contact Us
                    </a>
                  </li>
                  <li className='nav-item'>
                    <a className='nav-link text-muted' href='mailto:mk@everlastenergy.com'>
                      Partner with Us
                    </a>
                  </li>
                </ul>
              </div>
              {/* Widget 1 END */}
            </div>
          </div>
          {/* Widget 2 END */}
        </div>
        {/* Row END */}
        {/* Tops Links */}
        <div className='row mt-5 d-none'>
          <h5 className='mb-2 text-white'>Top Links</h5>
          <ul className='list-inline text-primary-hover lh-lg'>
            <li className='list-inline-item'>
              <a href='#' className='text-muted'>
                Retail Energy
              </a>
            </li>
            <li className='list-inline-item'>
              <a href='#' className='text-muted'>
                Solar Energy
              </a>
            </li>
            <li className='list-inline-item'>
              <a href='#' className='text-muted'>
                Sustainability
              </a>
            </li>
            <li className='list-inline-item'>
              <a href='#' className='text-muted'>
                Home Protection
              </a>
            </li>
            <li className='list-inline-item'>
              <a href='#' className='text-muted'>
                Energy Efficiency
              </a>
            </li>
          </ul>
        </div>
        {/* Payment and card */}
        <div className='row g-4 justify-content-between mt-0 mt-md-2'>
          {/* Payment card */}
          <div className='col-sm-7 col-md-6 col-lg-4'>
            <h5 className='text-white mb-2'>Payment &amp; Security</h5>
            <ul className='list-inline mb-0 mt-3'>
              <li className='list-inline-item'>
                <a>
                  <img src='assets/images/element/norton-logo.png' className='h-20px mx-auto' alt='' />
                </a>
              </li>
              <li className='list-inline-item'>
                <a href='https://www.eiqdigital.com/' target={'_blank'}>
                  <img src='https://powerrateindex.org/img/eiqdigital_logo.png' className='h-20px mx-auto' style={{ filter: 'invert(100%)' }} alt='EIQ Ditial Logo' />
                </a>
              </li>
              <li className='list-inline-item'>
                <a>
                  <img src='https://www.sophos.com/sites/default/files/2022-02/aws-logo-white-orange.png' className='h-20px mx-auto' alt='AWS Logo' />
                </a>
              </li>
            </ul>
          </div>
          {/* Social media icon */}
          <div className='col-sm-5 col-md-6 col-lg-3 text-sm-end'>
            <h5 className='text-white mb-2'>Follow us on</h5>
            <ul className='list-inline mb-0 mt-3'>
              <li className='list-inline-item'>
                <a className='btn btn-sm px-2 bg-facebook mb-0' href='https://facebook.com/everlastenergyinc'>
                  <i className='fab fa-fw fa-facebook-f' />
                </a>
              </li>
              <li className='list-inline-item'>
                <a className='btn btn-sm shadow px-2 bg-instagram mb-0' href='https://instagram.com/everlast_energy'>
                  <i className='fab fa-fw fa-instagram' />
                </a>
              </li>
              {/* <li class="list-inline-item">
          <a class="btn btn-sm shadow px-2 bg-twitter mb-0" href="#"><i class="fab fa-fw fa-twitter"></i></a>
        </li> */}
              <li className='list-inline-item'>
                <a className='btn btn-sm shadow px-2 bg-linkedin mb-0' href='https://www.linkedin.com/company/everlast-energy-llc/about/'>
                  <i className='fab fa-fw fa-linkedin-in' />
                </a>
              </li>
            </ul>
          </div>
        </div>
        {/* Divider */}
        <hr className='mt-4 mb-0' />
        {/* Bottom footer */}
        <div className='row'>
          <div className='container'>
            <div className='d-lg-flex justify-content-between align-items-center py-3 text-center text-lg-start'>
              {/* copyright text */}
              <div className='text-muted text-primary-hover me-5'>
                Copyrights
                <a href='#' className='text-muted'>
                  ©2023 Everlast Holdings Inc,
                </a>
                . All rights reserved.
              </div>
              {/* copyright links*/}
              Broker License Number #Br230032
              <div className='nav mt-2 mt-lg-0'>
                <ul className='list-inline text-primary-hover mx-auto mb-0'>
                  <li className='list-inline-item me-0'>
                    <a className='nav-link py-1 text-muted' href='https://everlastenergy.com/privacy-policy.html'>
                      Privacy policy
                    </a>
                  </li>
                  <li className='list-inline-item me-0'>
                    <a
                      className='nav-link py-1 text-muted'
                      href='https://everlastenergy.com/privacy-policy
                    '>
                      Terms and conditions
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
