import Header from '../components/header'
import { ContentPage } from '../components/styles/Page.styled'
import { Container } from 'react-bootstrap'

export const ShoppingTips = () => {
    return (
        <>
            <Header />
            <ContentPage>
                <Container>
                    <h1>Shopping Tips</h1>
                    <h2>
                        Does switching energy provider require me to do anything with my meter,
                        power line or house?
                    </h2>
                    <p>
                        No. Switching energy provider does not require any physical alternations to
                        your home. It is done 100% online with just a few clicks!
                    </p>
                    <h2>
                        What will happen if my current energy plan expires and I forget to switch to
                        a new plan?
                    </h2>

                    <p>
                        Your current energy provider has the right to move you to a variable rate
                        plan after your current plan expires. Your annual electricity cost can go up
                        significantly if market rates spike.
                    </p>
                    <h2>Is free energy really worth it?</h2>
                    <p>Hint – free electricity is not free</p>
                    <p>You’ve seen the ads. They’re everywhere!</p>
                    <p>
                        The Electricity Companies or Retail Energy Providers are selling a variety
                        of “Free Energy” plans targeting residential electricity customers across
                        Texas. These plans have become very popular in all parts of Texas especially
                        Houston and Dallas/Fort Worth.
                    </p>
                    <p>
                        Many of the largest Energy Companies are offering Free Electricity Plans,
                        most with slightly different terms and conditions but all with catchy names
                        to grab your attention. Some examples include:
                    </p>
                    <ul>
                        <li>Reliant Truly Free Weekends</li>
                        <li>TXU Free Nights & Solar Days</li>
                        <li>Direct Energy Free Power Weekends</li>
                        <li>Direct Energy Twelve Hour Power</li>
                    </ul>
                    <p>
                        The question most residential electricity customers are asking is,{' '}
                        <b>“Are Free Electricity Plans a Good Deal for Me?”</b>
                    </p>
                    <p>
                        The answer for the vast majority of residential electricity customers is,
                        NO!
                    </p>
                    <p>It’s a simple reason and here’s why.</p>
                    <p>
                        The large Energy Companies simply charge a higher than market rate for their
                        Non-Free hours to cover the cost of electricity used during “Free” hours.
                    </p>
                    <p>
                        As an example, at the time of writing this, Direct Energy’s 24-month Free
                        Power Weekends plan offered a rate of 19.4 cents/kWh compared to their
                        standard fixed price offering of 13.2 cents/kWh for the same term.
                    </p>
                    <p>
                        <b>
                            That’s an 47% premium on their Non-Free hours compared to their standard
                            fixed price rate!
                        </b>
                    </p>
                    <p>
                        Since weekends only account for about 27% of total weekly hours, most
                        customer’s electricity bill will be substantially higher on a “Free
                        Electricity” Plan than if they signed up for a competitive fixed price.
                    </p>
                    <p>
                        There is a very small percentage of residential electricity customers who
                        can take advantage of the Free Hours plans but those are the folks who only
                        use the vast majority of their electricity during the Free Hours.
                    </p>
                    <p>
                        If you are curious how these Free Electricity Plans add up for your home,
                        check out EverlastEnergy.com.
                    </p>
                    <h2>When do you choose Prepaid plan?</h2>
                    <p>
                        Prepaid plan makes electricity available to everyone without checking your
                        credit history. This plan is ideal for customers who have lower credit score
                        and want to better manage energy usage and cost.
                    </p>
                    <h2>Variable rate vs. Fixed rate plan</h2>
                    <p>
                        Do you still remember the winter storms and the power crisis in February
                        2021 in Texas? If you were on variable rate plan, your monthly electric bill
                        could have surged from $100 to $3,600 as power prices went up to $9,000 for
                        four days.
                    </p>
                    <p>
                        Some energy providers will automatically switch you from fixed rate to
                        variable rate if you forget to renew your plan upon expiration. Let us help
                        you select energy plans and manage the electric bill.
                    </p>
                    <h2>Why is our search engine needed in deregulated energy market</h2>
                    <p>
                        Deregulated energy market grants freedom to each consumer in their own
                        choice regarding their energy needs and usage. However, freedom comes with
                        burden. An informed decision requires knowledge, data, experience and hours
                        of research.
                    </p>
                    <p>
                        Our search engine combines decades of experience in the Texas energy
                        industry with AI technology to forecast usage, compare plans, and provide
                        personalized recommendations for you and your home.
                    </p>
                </Container>
            </ContentPage>
        </>
    )
}
