import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import {
    EnrollmentStepWrapper,
    EnrollmentStepFooter,
    EnrollmentFormRow,
    EnrollmentFormCol,
    EnrollmentAddressWrapper,
} from '../styles/Enrollment.styled'
import { Link } from 'react-router-dom'
import { Form, Spinner } from 'react-bootstrap'
import { Button } from '../styles/Button.styled'
import { RadioBox } from './RadioBox'
import { FormError } from './FormError'
import { getBlackoutDate, saveEnrollment } from '../../api/enrollment'
import { toast } from 'react-toastify'
import moment from 'moment'
import Datetime from 'react-datetime'
import { useLocation } from 'react-router'
import qs from 'qs'
import { Helmet } from 'react-helmet'

interface Props {
    show: boolean
    handleContinue: Function
    address: string
    zipcode: string
    city: string
    state: string
    esiid: string
    product: string
}

interface ServiceTypeOption {
    label: string
    value: string
    desc: string
}

export const EnrollmentStepService: React.FC<Props> = ({
    address,
    zipcode,
    city,
    state,
    esiid,
    show,
    product,
    handleContinue,
}) => {
    const location = useLocation()
    const { utility_code } = qs.parse(location.search, { ignoreQueryPrefix: true })

    const { t } = useTranslation('enrollment')
    const serviceTypeOptions = [
        {
            id: 'service-moving',
            label: t('Moving/NewService'),
            value: '1',
            desc: t('I am moving and establishing service under my name at this address'),
        },
        {
            id: 'service-switching-later',
            label: t('Switching (Specific Date)'),
            value: '2',
            desc: t('I am not moving. I want to switch to the new provider on a date I choose'),
        },
        {
            id: 'service-switching-immediately',
            label: t('Switching (Immediately)'),
            value: '3',
            desc: t(
                'I am not moving. I want to switch to the new provider as soon as possible (1-3 days)'
            ),
        },
    ]

    const [serviceType, setServiceType] = useState<ServiceTypeOption | undefined>(undefined)
    const [startDate, setStartDate] = useState(new Date())
    const [loading, setLoading] = useState(false)
    const [errorFields, setErrorFields] = useState<any>({})
    const [blackoutDate, setBlackoutDate] = useState<Array<any>>([])
    const [endDate, setEndDate] = useState<String>('')

    const validate = () => {
        let isValid = true
        let newErrorFields: any = {}
        if (!serviceType) {
            newErrorFields['serviceType'] = t('This field is required')
            isValid = false
        }
        if (
            !startDate ||
            !(
                moment(startDate, 'MM/DD/YYYY', true).isValid() ||
                moment(startDate, 'M/DD/YYYY', true).isValid() ||
                moment(startDate, 'MM/D/YYYY', true).isValid() ||
                moment(startDate, 'M/D/YYYY', true).isValid()
            )
        ) {
            newErrorFields['startDate'] = t('Invalid Date')
            isValid = false
        }
        setErrorFields(newErrorFields)
        return isValid
    }

    const handleSave = () => {
        if (loading || !validate()) return
        setLoading(true)

        let serviceTypeVal = 'MoveIn',
            requestedDate = moment().add(1, 'days').format('MM/DD/YYYY')
        if (serviceType && parseInt(serviceType.value) > 1) {
            serviceTypeVal = 'Switching'
        }
        if (serviceType && parseInt(serviceType.value) < 3) {
            requestedDate = moment(startDate).format('MM/DD/YYYY')
        }

        const saveFields = {
            esiid: esiid,
            service_address: address,
            service_zipcode: zipcode,
            service_city: city,
            service_state: state,
            service_type: serviceTypeVal,
            requested_date: requestedDate,
            product: product,
        }
        saveEnrollment(null, saveFields, null).then((res) => {
            setLoading(false)

            if (res && parseInt(res.status) === 1 && res.response) {
                const { enrollment_id } = res.response
                const { sign } = res.response
                if (enrollment_id) {
                    handleContinue(enrollment_id, sign)
                } else {
                    toast.error('Enrollment Id does not exist')
                }
            } else {
                toast.error(res.message)
            }
        })
    }
    useEffect(() => {
        const BlackoutDateList = async () => {
            const {
                response: { blackout_dates, end_date },
            } = await getBlackoutDate(product, zipcode, utility_code)
            setBlackoutDate(blackout_dates)
            setEndDate(end_date)
        }
        BlackoutDateList()
    }, [product, utility_code, zipcode])

    if (!show) return null
    return (
        <EnrollmentStepWrapper>
            <Helmet>
                <title>Move or Switch? Choose Your New Plan Effective Date | EverlastEnergy.com</title>
                <meta
                    name="description"
                    content="Pick a date for your new plan to take effect. You can pick today or a future date if you plan to change later."
                />
            </Helmet>
            <EnrollmentAddressWrapper>
                <div>
                    <p>
                        <b>{t('Address')}:</b>
                        {`${address} ${city} ${state}, ${zipcode}`}
                    </p>
                    <p>
                        <b>Esiid:</b>
                        {esiid}
                    </p>
                </div>
                <Link to="/" style={{ color: '#ED7549' }}>
                    {t('Not your address?')}
                </Link>
            </EnrollmentAddressWrapper>
            <EnrollmentFormRow>
                <EnrollmentFormCol full>
                    <Form.Group>
                        <RadioBox
                            title={`${t('Why do you need this service?')} *`}
                            options={serviceTypeOptions}
                            select={serviceType}
                            handleSelect={(val: any) => {
                                setServiceType(val)
                            }}
                        />
                        <FormError message={errorFields['serviceType']} />
                    </Form.Group>
                </EnrollmentFormCol>
            </EnrollmentFormRow>
            {serviceType && serviceType.value !== '3' && (
                <EnrollmentFormRow>
                    <EnrollmentFormCol>
                        <Form.Group>
                            <Form.Label>{t('Select Date')} *</Form.Label>
                            <Datetime
                                value={startDate}
                                onChange={(date: any) => setStartDate(date)}
                                dateFormat="MM/DD/YYYY"
                                timeFormat={false}
                                closeOnSelect={true}
                                inputProps={{
                                    placeholder: t('MM/DD/YYYY'),
                                    id: 'select-start-date',
                                    readOnly: true
                                }}
                                isValidDate={(currentDate) => {
                                    if (endDate === '') {
                                        if (
                                            currentDate.isBefore(moment()) ||
                                            blackoutDate.indexOf(
                                                currentDate.format('YYYY-MM-DD')
                                            ) >= 0
                                        ) {
                                            return false
                                        } else {
                                            return true
                                        }
                                    } else {
                                        if (currentDate.isBefore(endDate)) {
                                            if (
                                                currentDate.isBefore(moment()) ||
                                                blackoutDate.indexOf(
                                                    currentDate.format('YYYY-MM-DD')
                                                ) >= 0
                                            ) {
                                                return false
                                            } else {
                                                return true
                                            }
                                        } else {
                                            return false
                                        }
                                    }
                                }}
                            />
                        </Form.Group>
                        <FormError message={errorFields['startDate']} />
                    </EnrollmentFormCol>
                </EnrollmentFormRow>
            )}
            <EnrollmentStepFooter>
                <div />
                <Button onClick={() => handleSave()} data-test-id="cta">
                    <span>{t('Continue')}</span>
                    {loading && (
                        <Spinner animation="border" variant="light" size="sm" className="ms-2" />
                    )}
                </Button>
            </EnrollmentStepFooter>
        </EnrollmentStepWrapper>
    )
}
