import React from 'react';
import { LoadingRow } from '../components/loading-row';
import { CheckboxItem } from '../components/checkbox-item';
import type { ProviderFilterProps } from '../types';

export const ProviderFilter: React.FC<ProviderFilterProps> = ({
    initializeFilter,
    value,
    loading,
    handleClick,
    availableReps,
    reps
}) => {
    if (initializeFilter) return <LoadingRow size="sm" />
    return (
        <div className="plans-filter-options">
            <CheckboxItem
                label="All"
                value="-1"
                key={-1}
                checked={value.length === 0}
                handleClick={() => {
                    handleClick('');
                }}
                disabled={loading}
            />
            {
                reps.map((val: any, idx) => (
                    <CheckboxItem
                        label={val.name}
                        value={val.provider_id}
                        key={idx}
                        checked={value.indexOf(val.provider_id) >= 0}
                        handleClick={(val: string) => {
                            handleClick(val);
                        }}
                        onlyread={availableReps.indexOf(val.provider_id) < 0}
                        disabled={loading}
                    />
                ))
            }
        </div>
    )
}