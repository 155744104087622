import React, { MouseEventHandler } from "react";

export default function MobileNavbar({getQuote}:{getQuote: MouseEventHandler}) {
  return (
    <>
      {/* Navbar mobile START */}
      <div className="navbar navbar-mobile">
        <ul className="navbar-nav">
          {/* Nav item Home */}
          <li className="nav-item">
            <a
              className="nav-link"
              onClick={getQuote}
            >
              <i className="bi bi-messenger fa-fw" />
              <span className="mb-0 nav-text">Get quote</span>
            </a>
          </li>

          <li className="nav-item">
            <a
              className="nav-link"
              href="tel:(346) 330-1875"
            >
              <i className="bi bi-telephone fa-fw" />
              <span className="mb-0 nav-text">Contact</span>
            </a>
          </li>

          {/* Nav item My Trips */}
          <li className="nav-item">
            <a className="nav-link" href="https://career.everlastenergy.com">
              <i className="bi bi-currency-dollar fa-fw" />
              <span className="mb-0 nav-text">Careers</span>
            </a>
          </li>
          {/* Nav item Offer */}
          {/* <li className="nav-item d-none">
            <a className="nav-link" href="https://everlastenergy.com">
              <i className="bi bi-blockquote-right fa-fw" />
              <span className="mb-0 nav-text">Blog</span>
            </a>
          </li> */}
          {/* Nav item Account */}
          <li className="nav-item">
            <a className="nav-link" href="https://career.everlastenergy.com/#whoweare">
              <i className="bi bi-people fa-fw" />
              <span className="mb-0 nav-text">About Us</span>
            </a>
          </li>
        </ul>
      </div>
      {/* Navbar mobile END */}

      <div className="back-top back-top-show"></div>
    </>
  );
}
