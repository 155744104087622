import styled from 'styled-components';

export const ContentPage = styled.div`
    padding: 32px 24px;
    margin: 0 auto;

    h1 {
        margin-bottom: 44px;
    }

    h2 {
        font-size: 24px;
        margin-bottom: 12px;
    }

    p {
        font-size: 14px;
    }

    a {
        color: #ED7549;
    }
`