import styled from 'styled-components';

export const TduContactLaterSection = styled.div`
    position: relative;
    margin-top: 88px;

    ::before{
        display: inline-block;
        content: ' ';
        width: 300px;
        height: 1px;
        background-color: #ddd;
        position: relative;
        top: -44px;
    }
`