import i18n from "i18next";
import {initReactI18next} from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import en from "./locales/en/translation.json";
import cn_zh from "./locales/cn_zh/translation.json";
import es from "./locales/es/translation.json";

const languageDetector = new LanguageDetector();

languageDetector.addDetector({
    name: 'customDetector',
    lookup() {
        if (typeof window !== undefined) {
            const pathname = window.location.pathname;
            if (pathname.indexOf('/dian') >= 0) {
                return 'es';
            }
        }
        return 'en';
    }
});

const detectionOptions = {
    caches: ['cookie'],
    cookieSameSite: 'strict',
    lookupQuerystring: 'lang', // default is lng
    lookupCookie: 'next-i18next',
    order: ['querystring', 'customDetector', 'cookie', 'header'], // IMPORTANT
}

const resources = {
    en,
    cn_zh,
    es,
}

i18n
.use(initReactI18next)
.use(languageDetector)
.init({
    resources,
    detection: detectionOptions,
    defaultNS: "common",
    fallbackLng: "en",
    interpolation: {
        escapeValue: false
    }
});