type PUCT_number_type = {
    [key: string]: string;
}

export const PUCT_number: PUCT_number_type = {
    '210': '10259', // Pulse
    '194': '10105', // APGE
    '183': '10098', // Champion
    '239': '10279', // Rhythm
    '258': '10293', // Value Power
    '196': '10014', // Constellation
    '269': '10308', // GoodCharlie
    '285': '10298', //Mothership
};