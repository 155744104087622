import React, { useState, useEffect } from "react";
import { Modal, Image } from "react-bootstrap";
import { ValidateAddress } from "../validate_address";

export const ValidateAddressModal = ({
  show,
  handleClose,
}: {
  show: boolean;
  handleClose: Function;
}) => {
  return (
    <Modal
      id="detailModal"
      size="lg"
      centered
      show={show}
      onHide={() => {
        handleClose();
      }}
    >
      <Modal.Header closeButton>&nbsp;</Modal.Header>
      <Modal.Body>
        <div className="modal-header d-flex flex-column border-0 py-5">
          <div className="position-relative w-100 mb-4 px-1 px-md-5 mb-md-0">
            {/* Title */}
            <h3 className="my-1">We Shopped, You Save</h3>
            <p>Enter your address below and see how much!</p>
            <ValidateAddress btnText="See My Rates" btnColor="orange" />
            {/* Info */}
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
};
