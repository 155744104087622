import { useTranslation } from "react-i18next";
import { StyledProcessBar, ProcessBarItem } from "../styles/ProcessBar.styled";

interface Props {
    step: number
}

export const ProcessBar: React.FC<Props> = ({ step }) => {
    const { t } = useTranslation('enrollment');

    const propcess = [
        t('Service Start Date'),
        t('Information'),
        t('Review')
    ];

    if (step < 1 || step > 3) return null;

    return (
        <StyledProcessBar>
            {
                propcess.map((val, idx) => (
                    <ProcessBarItem active={idx + 1 === step} key={idx}>
                        {`${idx + 1}. ${val}`}
                    </ProcessBarItem>
                ))
            }
        </StyledProcessBar>
    )
}