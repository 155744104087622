import styled from 'styled-components';

interface ButtonProps {
    variant?: string;
    md?: boolean;
    disabled?: boolean;
}

export const Button = styled.button<ButtonProps>`
    background: ${props => props.disabled ? (props.variant === 'secondary' ? '#fff' : 'rgb(237, 117, 73 ,.8)') : (props.variant === 'secondary' ? '#fff' : '#ED7549')};
    color: ${props => props.variant === 'secondary' ? '#777' : '#fff'};
    border: ${props => props.variant === 'secondary' ? '1px solid #eee' : '0 none'};
    font-weight: 600;
    font-size: ${props => props.md ? '14px' : '16px'};
    padding: ${props => props.md ? '8px 16px' : '12px 32px'};
    border-radius: 40px;
    cursor: ${props => props.disabled ? 'default' : 'pointer'};
    transition: opacity .3s;
    box-shadow: ${props => props.variant === 'secondary' ? 'rgb(185 185 185 / 40%) 0px 3px 8px' : '0 3px 8px rgba(237, 117, 73, .4)'};
    :hover{
        opacity: ${props => props.disabled ? '1' : '.85'};
    }
`