import React, { useEffect, useState } from 'react';
import { useTranslation } from "react-i18next";
import { Form, Modal } from 'react-bootstrap';
import { css } from '@emotion/css';
import { Button } from '../components/styles/Button.styled';
import type { UsageAdjustProps } from '../types';
import { BarChart, Bar, Cell, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer } from 'recharts';
import moment from 'moment';

export const UsageAdjust: React.FC<UsageAdjustProps> = ({
    usage,
    disabled,
    handleChangeUsage,
    handleReset,
    isEstMode,
    handleSwitchToEstMode,
    usageFrom
}) => {
    const { t } = useTranslation('plan');
    const [ values, setValues ] = useState<any>(undefined);
    const [ showAdjustModal, setShowAdjustModal ] = useState(false);
    const [ isSimpleMode, setIsSimpleMode ] = useState(false);
    const currentMonth = moment().format('M');

    const months = [t('Jan', {ns:'common'}), t('Feb', {ns:'common'}), t('Mar', {ns:'common'}), t('Apr', {ns:'common'}), t('May', {ns:'common'}), t('Jun',{ns:'common'}),
    t('Jul', {ns:'common'}), t('Aug', {ns:'common'}), t('Sep', {ns:'common'}), t('Oct', {ns:'common'}), t('Nov', {ns:'common'}), t('Dec', {ns:'common'})];

    useEffect(() => {
        if (usage) setValues(usage);
    }, [usage])

    if (!values) return null;

    let chartData = [];
    for (let i in values) {
        chartData.push({ name: months[parseInt(i) - 1], value: parseInt(values[i]) })
    }

    const handleCloseModal = () => {
        setShowAdjustModal(false);
    }

    return (
        <div>
            <div className="plans-filter mb-0">
                <div className="plans-filter-title d-flex align-items-center">
                    {t(`Your ${isEstMode ? 'Estimated' : 'Actual'} Usage`)}
                    {isEstMode ? <Button style={{fontSize:12,padding:'4px 12px',marginLeft:12,textTransform:'uppercase'}} onClick={() => {
                        setShowAdjustModal(true);
                    }}>{t('Adjust')}</Button> :
                    <Button style={{fontSize:12,padding:'4px 12px',marginLeft:12}} onClick={() => {
                        handleSwitchToEstMode();
                    }}>{t('Switch to Estimate Mode')}</Button>
                }</div>
                {
                    isEstMode &&
                    <div style={{fontSize:14,color:'#666',lineHeight:1.3,marginBottom:20}}>{t('Rate is calculated by estimated usage by zip code and house size. Adjust with your own estimates.')}</div>
                }
                <div id="usage-chart">
                    <ResponsiveContainer width="100%" height={130}>
                        <BarChart
                            data={chartData}
                            margin={{
                                top: 12, right: 24, left: -12, bottom: 0,
                            }}
                        >
                            <XAxis dataKey="name" tick={{fontSize:11}} stroke="#999" interval={1} />
                            <YAxis tick={{fontSize:11, fill: '#999'}} stroke="#fff" />
                            <CartesianGrid vertical={false} strokeDasharray="2" />
                            <Tooltip 
                                itemStyle={{
                                    fontSize: 13,
                                    fontWeight: 'bold',
                                    color: '#fff'
                                }}
                                labelStyle={{
                                    fontSize: 11,
                                    fontWeight: 'normal',
                                    color: '#fff'
                                }}
                                contentStyle={{
                                    backgroundColor: 'rgba(0, 0, 0, .6)',
                                    border: 0,
                                    borderRadius: 4
                                }}
                                cursor={{
                                    fill: '#f7f7f7'
                                }}
                                content={({ active, payload, label }) => {
                                    if (active && label && payload) {
                                        return (
                                            <div className="chart-tooltip">
                                                <div className="date">{label}</div>
                                                <div className="value">{payload[0].value + 'kwh'}</div>
                                            </div>
                                        );
                                    }
                                    return null;
                                }}
                            />
                            <Bar dataKey="value"
                                isAnimationActive={true}
                                radius={10}
                                maxBarSize={10}
                            >
                                {chartData.map((entry, idx) => (
                                    <Cell key={idx} fill="#D1D9D7" />
                                ))}
                            </Bar>
                        </BarChart>
                    </ResponsiveContainer>
                </div>
                {
                    (usageFrom && usageFrom !== 'undefined') &&
                    <div style={{marginTop:-4,fontSize:13,color:'#999',fontStyle:'italic',paddingLeft:2}}>{t('Actual usage data from')} <span style={{textTransform:'uppercase'}}>{usageFrom}</span></div>
                }
            </div>
            <Modal show={showAdjustModal} onHide={handleCloseModal}>
                <Modal.Header closeButton>
                    <Modal.Title>{t('Estimated monthly usage (kWh)')}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="usage-adjust-row">
                        {
                            isSimpleMode &&
                            <div className="usage-adjust-item ps-2 pe-2">
                                <Form.Group style={{marginTop:8,marginBottom:8}}>
                                    <Form.Label>{t('Usage')}</Form.Label>
                                    <Form.Control
                                        required
                                        onChange={evt => {
                                            const val = evt.currentTarget.value;
                                            let newValues = Object.assign({}, values);
                                            for (let i in newValues) {
                                                newValues[i] = val ? parseInt(val) : 0;
                                            }
                                            setValues(newValues);
                                        }}
                                        value={values[currentMonth]}
                                        disabled={disabled}
                                    />
                                </Form.Group>
                            </div>
                        }
                        {
                            !isSimpleMode &&
                            Object.keys(values).map((key) => (
                                <div className="usage-adjust-item ps-2 pe-2" key={key}>
                                    <Form.Group style={{marginTop:8,marginBottom:8}}>
                                        <Form.Label>{months[parseInt(key) - 1]}</Form.Label>
                                        <Form.Control
                                            required
                                            onChange={evt => {
                                                const val = evt.currentTarget.value;
                                                let newValues = Object.assign({}, values);
                                                newValues[key] = val ? parseInt(val) : 0;
                                                setValues(newValues);
                                            }}
                                            value={values[key]}
                                            disabled={disabled}
                                        />
                                    </Form.Group>
                                </div>
                            ))
                        }
                    </div>
                    <div className="text-center mt-4 mb-3">
                        <button className={css`
                            color: #ED7549;
                            background-color: transparent;
                            border: 0 none;
                            font-weight: 500;
                            border-bottom: 1px dashed #ED7549;
                        `} onClick={() => {setIsSimpleMode(!isSimpleMode)}}>{
                            isSimpleMode ? t('Prefer more accurate predication?') : t('Prefer simple monthly view?')
                        }</button>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <div className="d-flex flex-align-center justify-content-center">
                        <Button onClick={() => {
                            handleReset();
                            handleCloseModal();
                        }} disabled={disabled} variant="secondary" style={{marginRight:12}}>{t('Reset')}</Button>
                        <Button onClick={() => {
                            handleChangeUsage(values);
                            handleCloseModal();
                        }} disabled={disabled}>{t('Confirm')}</Button>
                    </div>
                </Modal.Footer>
            </Modal>
        </div>
    )
}
