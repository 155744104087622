import React, { useState } from "react";
import { useTranslation, Trans } from "react-i18next";
import { Dropdown, Form, Modal } from 'react-bootstrap';
import type { ExitIntentModalProps } from '../types';
import { Button } from "./styles/Button.styled";
import { FormError } from "./enrollment/FormError";
import { FormLoading } from "./form-loading";
import { fetchReportProspect } from "../api/api";
import { useCookies } from "react-cookie";
import moment from "moment";

export const ExitIntentModal: React.FC<ExitIntentModalProps> = ({
    show,
    handleClose
}) => {
    const { t } = useTranslation('common');
    const [ email, setEmail ] = useState('');
    const [ contractEndYear, setContractEndYear ] = useState('');
    const [ contractEndMonth, setContractEndMonth ] = useState('');
    const [ errorMsg, setErrorMsg ] = useState('');
    const [ loading, setLoading ] = useState(false);
    const [ cookies, setCookie ] = useCookies(['show_subscribe_popup']);

    const submit = () => {
        if (loading) return;
        setErrorMsg('');
        const pattern = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/
        if (!email) {
            setErrorMsg(t('This field is required'));
            return;
        }
        if (!pattern.test(email)) {
            setErrorMsg(t('Invalid emaill address format'));
            return;
        }

        setLoading(true);
        let date = '';
        if (contractEndYear || contractEndYear) {
            date = `${contractEndMonth ? contractEndMonth : '00'}/${contractEndYear ? contractEndYear : '0000'}`;
        }
        fetchReportProspect(email, date).then(res => {
            setLoading(false);
            handleClose();
            if (cookies.show_subscribe_popup) {
                const res: any = decodeURIComponent(cookies.show_subscribe_popup).split('|');
                res[2] = 1;
                setCookie('show_subscribe_popup', res.join('|'), { path: '/', maxAge: 2147483647 });
            }
            else {
                setCookie('show_subscribe_popup', '||1', { path: '/', maxAge: 2147483647 });
            }
        })
    }

    return (
        <Modal show={show} onHide={() => {
            const val = moment().add(60, 'days').format('YYYY-MM-DD');
            if (cookies.show_subscribe_popup) {
                const res: any = decodeURIComponent(cookies.show_subscribe_popup).split('|');
                res[1] = val;
                setCookie('show_subscribe_popup', res.join('|'), { path: '/', maxAge: 2147483647 });
            }
            else {
                setCookie('show_subscribe_popup', `|${val}|`, { path: '/', maxAge: 2147483647 });
            }
            handleClose();
        }}>
            <Modal.Header closeButton>
                <Modal.Title style={{maxWidth:400,margin:'36px auto -16px',lineHeight:1.1}}>
                    <Trans
                        i18nKey="common:exitIntentModalTitle"
                        components={[<br />]}
                    />
                    {/* {t('Subscribe to our newsletter for market rates drop')}? */}
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form>
                    <Form.Group>
                        <Form.Label>{t('Email Address')} *</Form.Label>
                        <Form.Control
                            type="text"
                            placeholder="name@example.com"
                            autoFocus
                            value={email}
                            onChange={(evt) => {
                                setEmail(evt.target.value);
                            }}
                            required
                        />
                    </Form.Group>
                    <FormError message={errorMsg} />
                    {/* <p style={{fontSize:13,color:'#666'}}>{t('Leave your email address, if you would like to receive notifications when market rates drop')}</p> */}
                    <div style={{borderTop:'1px solid #eee',paddingTop:24,marginTop:32}}>
                        <p style={{fontSize:13,color:'#666'}}>{t('Leave your contract expiration month, if you would like to receive a reminder and a list of best rated plans 30 days prior to expiration')}</p>
                        <Form.Group>
                            <Form.Label>{t('Contract Expiration Month/Year')} ({t('optional')})</Form.Label>
                            <div className="d-flex gap-3">
                                <Dropdown>
                                    <Dropdown.Toggle variant="outline-secondary" style={{borderColor:'#ced4da'}}>
                                        { contractEndMonth ? contractEndMonth : `${t('Month')}...` }
                                    </Dropdown.Toggle>
                                    <Dropdown.Menu>
                                        {['1', '2', '3', '4', '5', '6', '7', '8', '9', '10', '11', '12'].map((val, idx) => (
                                            <Dropdown.Item
                                                eventKey={val}
                                                key={idx}
                                                onClick={() => {
                                                    setContractEndMonth(val)
                                                }}
                                                active={ contractEndMonth === val }
                                            >
                                                {val}
                                            </Dropdown.Item>
                                        ))}
                                    </Dropdown.Menu>
                                </Dropdown>
                                <Dropdown>
                                    <Dropdown.Toggle variant="outline-secondary" style={{borderColor:'#ced4da'}}>
                                        { contractEndYear ? contractEndYear : `${t('Year')}...` }
                                    </Dropdown.Toggle>
                                    <Dropdown.Menu>
                                        {['2022', '2023', '2024', '2025', '2026'].map((val, idx) => (
                                            <Dropdown.Item
                                                eventKey={val}
                                                key={idx}
                                                onClick={() => {
                                                    setContractEndYear(val)
                                                }}
                                                active={ contractEndYear === val }
                                            >
                                                {val}
                                            </Dropdown.Item>
                                        ))}
                                    </Dropdown.Menu>
                                </Dropdown>
                            </div>
                        </Form.Group>
                    </div>
                    <FormLoading show={loading} />
                    <div className="text-center mt-5">
                        <Button type="button" onClick={submit}>{t('Submit', { ns: 'enrollment' })}</Button>
                    </div>
                </Form>
            </Modal.Body>
        </Modal>
    )
}