import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useLocation } from "react-router-dom";
import { BLOG_URL } from "../config";
import qs from "qs";
import { IS_DEV } from "../config";

const Header = () => {
  const { t, i18n } = useTranslation();
  const location = useLocation();
  const navigate = useNavigate();
  const [lang, setLang] = useState({ label: "Español", value: "es" });
  const [showNav, setShowNav] = useState(false);

  const titleColor = location.pathname === "/" ? "#FFFFFF" : "#000000";

  useEffect(() => {
    if (i18n.language === "en") {
      setLang({ label: "Español", value: "es" });
    } else {
      setLang({ label: "English", value: "en" });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const path = IS_DEV ? "/dev" : "";
  const nav = [
    {
      label: t("About Us"),
      url:
        i18n.language === "en" ? `${path}/about_us/` : `${path}/about_us_cn/`,
    },
    {
      label: t("Contact"),
      url:
        i18n.language === "en"
          ? `https://everlastenergy.com/contact-us/`
          : `${BLOG_URL}联系我们/`,
    },
    { label: t("Blog"), url: BLOG_URL },
  ];

  const changeLang = () => {
    const currentLang = i18n.language;

    if (currentLang === "en") {
      i18n.changeLanguage("es");
      setLang({ label: "English", value: "en" });
    } else {
      i18n.changeLanguage("en");
      setLang({ label: "Español", value: "es" });

      if (typeof window !== undefined) {
        const { pathname, href } = window.location;
        if (pathname.indexOf("/dian") >= 0) {
          const newHref = href.replace(/\/dian/, "");
          window.location.href = newHref;
        }
      }
    }
    const { lang } = qs.parse(location.search, { ignoreQueryPrefix: true });
    if (lang) {
      let newLang = currentLang === "en" ? "es" : "en";
      const newQuery = updateQueryStringParameter(
        location.search,
        "lang",
        newLang
      );
      navigate(`${location.pathname}${newQuery}`, { replace: true });
    }
  };

  const updateQueryStringParameter = (
    uri: string,
    key: string,
    value: string
  ) => {
    var re = new RegExp("([?&])" + key + "=.*?(&|$)", "i");
    var separator = uri.indexOf("?") !== -1 ? "&" : "?";
    if (uri.match(re)) {
      return uri.replace(re, "$1" + key + "=" + value + "$2");
    } else {
      return uri + separator + key + "=" + value;
    }
  };

  return (
    <>
      {/* <!-- Header START --> */}
      <header className="navbar-light header-sticky">
        {/* <!-- Logo Nav START --> */}
        <nav className="navbar navbar-expand-xl">
          <div className="container">
            {/* <!-- Logo START --> */}
            <a className="navbar-brand" href="#">
              <img
                className="light-mode-item navbar-brand-item"
                src="images/logo.png"
                alt="logo"
              />
              <img
                className="dark-mode-item navbar-brand-item"
                src="images/logo.png"
                alt="logo"
              />
            </a>
            {/* <!-- Logo END --> */}

            {/* <!-- Responsive navbar toggler --> */}
            <button
              className="navbar-toggler ms-auto ms-sm-0 p-0 p-sm-2"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#navbarCollapse"
              aria-controls="navbarCollapse"
              aria-expanded="false"
              aria-label="Toggle navigation"
            >
              <span className="navbar-toggler-animation">
                <span></span>
                <span></span>
                <span></span>
              </span>
              <span className="d-none d-sm-inline-block small">Menu</span>
            </button>
            <div className="navbar-collapse collapse" id="navbarCollapse">
              <ul className="navbar-nav navbar-nav-scroll ms-auto">
                {/* <!-- Nav item Components --> */}
                <li className="nav-item">
                  <a className="nav-link" href="https://career.everlastenergy.com/#whoweare">
                    About Us
                  </a>
                </li>
                <li className="nav-item">
                  <a className="nav-link" href="https://career.everlastenergy.com/">
                    Careers
                  </a>
                </li>
                {/* <li className="nav-item d-none">
                  <a className="nav-link" href={BLOG_URL}>
                    Blog
                  </a>
                </li> */}
              </ul>
            </div>
          </div>
        </nav>
        {/* <!-- Logo Nav END --> */}
      </header>
      <div id="sticky-space" style={{ height: 0 }}></div>
      {/* <!-- Header END --> */}
    </>
  );
};

export default Header;
